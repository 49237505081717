var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-container"},[_c('van-form',{on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('van-field',{attrs:{"name":"phone","label":"手机号","type":"tel","maxlength":"13","placeholder":"请输入手机号","rules":[
        { required: true, message: '请填写手机号', trigger: 'onSubmit' },
        {
          pattern: _vm.phoneVerify,
          message: '请填写正确的手机号',
          trigger: 'onSubmit',
        } ]},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('van-field',{attrs:{"name":"code","label":"验证码","maxlength":"6","placeholder":"请输入验证码","rules":[
        { required: true, message: '请填写验证码', trigger: 'onSubmit' } ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{staticStyle:{"width":"78px"},attrs:{"native-type":"button","size":"small","type":"info","disabled":_vm.timerValue ? true : false},on:{"click":_vm.getPhoneCode}},[_vm._v(" "+_vm._s(_vm.timerValue ? _vm.timerValue : "获取验证码")+" ")])]},proxy:true}]),model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('div',{staticClass:"option"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit","loading":_vm.loading,"disabled":_vm.disabled,"loading-text":"加载中..."}},[_vm._v(" 登录 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }