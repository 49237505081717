<template>
  <div class="login-container">
    <van-form @submit="onSubmit" @failed="onFailed">
      <van-field
        v-model="formData.phone"
        name="phone"
        label="手机号"
        type="tel"
        maxlength="13"
        placeholder="请输入手机号"
        :rules="[
          { required: true, message: '请填写手机号', trigger: 'onSubmit' },
          {
            pattern: phoneVerify,
            message: '请填写正确的手机号',
            trigger: 'onSubmit',
          },
        ]"
      />
      <van-field
        v-model="formData.code"
        name="code"
        label="验证码"
        maxlength="6"
        placeholder="请输入验证码"
        :rules="[
          { required: true, message: '请填写验证码', trigger: 'onSubmit' },
        ]"
      >
        <template #button>
          <van-button
            native-type="button"
            @click="getPhoneCode"
            size="small"
            type="info"
            :disabled="timerValue ? true : false"
            style="width: 78px"
          >
            {{ timerValue ? timerValue : "获取验证码" }}
          </van-button>
        </template>
      </van-field>
      <div class="option">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :loading="loading"
          :disabled="disabled"
          loading-text="加载中..."
        >
          登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Notify, Toast } from "vant";
import "@/uitls/TCaptcha";
import "./index.styl";
import { setToken } from "@/uitls/cookies";
import { apiSmsCode, apiLogin } from "@/request";
export default {
  data() {
    return {
      appid: "2049979663",
      appKey: "w_h5ecg",
      initTimerValue: 60,
      timerValue: "",
      loading: false,
      disabled: true,
      phoneVerify: /^((1[3-9]\d{9}))$/, // /^((0\d{2,3}-\d{8})|(1[3-9]\d{9}))$/,
      formData: {
        phone: "",
        code: "",
      },
    };
  },
  methods: {
    async onSubmit(values) {
      console.log(values);
      let params = JSON.parse(JSON.stringify(values));
      params.appKey = this.appKey;
      const { resultCode, resultData, resultMsg } = await apiLogin({
        ...params,
      });
      if (resultCode === "200") {
        console.log(resultData);
      } else Toast.fail(resultMsg);
    },
    onFailed(err) {
      if (err.errors.length > 0) {
        Notify({ type: "danger", message: err.errors[0].message });
      }
    },
    // 获取手机验证码
    getPhoneCode() {
      // 验证手机号
      if (!this.formData.phone) return Toast("请填写手机号");
      else {
        let isPhone = this.phoneVerify.test(this.formData.phone);
        if (!isPhone) return Toast("请填写正确的手机号!");
      }

      const _this = this;

      // eslint-disable-next-line no-undef
      let captcha = new TencentCaptcha(_this.appid, function (res) {
        // 返回结果
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，请求后台接口时需带上。
        // console.log(res)

        // res（用户主动关闭验证码）= {ret: 2, ticket: null}
        // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
        if (res.ret === 0) {
          let result = { randstr: res.randstr, ticket: res.ticket };
          _this.smsCode(result);
        }
        if (res.ret === 2) {
          console.log("取消校验");
        }
      });
      captcha.show();
    },

    // 发送验证码
    async smsCode(values) {
      const params = {
        appKey: this.appKey,
        phone: this.formData.phone,
        randstr: values.randstr,
        ticket: values.ticket,
      };
      Toast.loading({ message: "加载中...", forbidClick: true, duration: 0 });

      const { resultCode, resultMsg } = await apiSmsCode({
        ...params,
      });

      if (resultCode == "200") {
        Toast.success("验证码已发送");
        let timer = setInterval(() => {
          this.timerValue = this.timerValue
            ? this.timerValue - 1
            : this.initTimerValue - 1;
          if (this.timerValue === 0) {
            clearInterval(timer);
            this.timerValue = "";
          } // 退出倒计时
        }, 1000);
      } else Toast.fail(resultMsg);
    },
  },
  watch: {
    "formData.phone"(val) {
      if (val && this.formData.code) this.disabled = false;
      else this.disabled = true;
    },
    "formData.code"(val) {
      if (val && this.formData.phone) this.disabled = false;
      else this.disabled = true;
    },
  },
};
</script>